import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table__td-faq-question" }

import { ref } from 'vue';
import { ITableCellProps } from '@/components/Table/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionTd',
  props: {
    dataKey: {},
    rowData: {},
    rowIndex: {},
    value: {},
    class: {}
  },
  setup(__props: any) {

const props = __props;
const question = props.value;
const alternative_question = props.rowData.faq_question_variants.map((item: { variant_text: string}) => item.variant_text);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", _hoisted_1, [
    _createElementVNode("p", null, [
      _cache[0] || (_cache[0] = _createElementVNode("i", { class: "mdi mdi-file-check" }, null, -1)),
      _createTextVNode(" " + _toDisplayString(_unref(question)), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(alternative_question), (alt, index) => {
      return (_openBlock(), _createElementBlock("p", { key: index }, [
        _cache[1] || (_cache[1] = _createElementVNode("i", { class: "mdi mdi-file-delimited" }, null, -1)),
        _createTextVNode(" " + _toDisplayString(alt), 1)
      ]))
    }), 128))
  ]))
}
}

})