import { get, post, put, del } from "@/service/bffService";
import {
  TFaqGetListResDto, 
  TFaqItemResDto, 
  TFaqCategoryListDto,
  TFaqCreate,
  TFaqUpdate,
  TCategoriesGetListResDto,
  TCategoryPostedGetItemResDto,
} from "./types/dto";
export { TBffResponseError } from '@/service/bffService';

export type TFaqSearchParams = {
  filter_id_question?: string;
	filter_category?: string;
	filter_question?: string;
  filter_confirmed?: string;
}

export type TCategoriesSearchParams = {
  name: string;
  description: string;
}

// Faq list
export const getFaqQuestionsList = async (page: number, perPage: number, search?: TFaqSearchParams): Promise<TFaqGetListResDto> => {
  const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&');

  return get<TFaqGetListResDto>(`/api/faq-pannel-questions?perPage=${perPage}&page=${page}&${searchParams}`);
}

// Faq list
export const getFaqPopulateAll = async (): Promise<any> => {
  return get<any>(`/api/faq-populate-questions`);
}

// Faq item
export const getFaqItem = async (id: number): Promise<TFaqItemResDto> => {
  return get<TFaqItemResDto>('/api/faq-edit-questions/' + id);
}

export const updateFaq = async (id: number, data: TFaqUpdate): Promise<any> => {
  return put<TFaqUpdate, { status: boolean }>('/api/faq-edit-questions/' + id, data);
}

export const updateFaqAnswer = async (id: number, data: TFaqUpdate): Promise<any> => {
  return put<TFaqUpdate, { status: boolean }>('/api/faq-answer/' + id, data);
}

export const createFaq = async (data: TFaqCreate): Promise<{ status: boolean }> => {
  return post<TFaqCreate, { status: boolean }>('/api/faq-create-questions', data);
};

export const deleteFaq = async (id: number): Promise<{ status: boolean }> => {
  return del<{ status: boolean }>('/api/faq-edit-questions/' + id);
};

// delete faq question variants
export const deleteFaqQuestionVariant = async (id: number): Promise<{ status: boolean }> => {
  return del<{ status: boolean }>('/api/faq-question-variants/' + id);
};

// Faq list
export const getFaqCategories = async (): Promise<TFaqCategoryListDto> => {
  return get<TFaqCategoryListDto>(`/api/faq-get-categories`);
}

// Faq category
export const getFaqCategory = async (page: number,perPage: number,search?: TCategoriesSearchParams): Promise<TCategoriesGetListResDto> => {
  const searchParams = Object.entries(search || {}).map(filter=>`${filter[0]}=${encodeURI(filter[1])}`).join('&');

  return get<TCategoriesGetListResDto>(`/api/faq-get-category?perPage=${perPage}&page=${page}&${searchParams}`);
};

export const createCategory = async (data: TCategoryPostedGetItemResDto): Promise<TCategoryPostedGetItemResDto> => {
  return post('/api/faq-get-category', data);
};

export const updateCategory = async (id: number, data: object): Promise<any> => {
  return put<any, object>('/api/faq-get-category/' + id, data);
}

export const deleteCategory = async (id: number): Promise<any> => {
  return del<any>(`/api/faq-get-category/` + id);
};

export const getJsonCategory = async (category: number): Promise<any> => {
  return get<any>(`/public-api/faq?category=${category}&key=810fb4aac6802adac68f62478d3889dfc1a011d79e74915405a4b38bb2179b7f014d2863eb9c10881b3e754de3ec71f17aebf2d53c051b1dcfa8a86e8fba0845b5fe8903ebb24b36ff3a922a997fb35fff3309e7cc3bb2c2f9d54765ec3e96d55e20c0d8e5550d77aafbc9a8e25f793df60d89a1fbfcda926257e27902da567a`);
};