import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "toast-icon" }
const _hoisted_2 = { class: "toast-text" }
const _hoisted_3 = { class: "jq-toast-heading" }

import { computed, ref, watch, nextTick } from 'vue';
import { useNotification } from './notificationService';


export default /*@__PURE__*/_defineComponent({
  __name: 'ToastMessage',
  setup(__props) {

const { notification } = useNotification();
const fadeOut = ref(false);
const notificationsQueue = ref<any[]>([]);  // Массив уведомлений
const isVisible = computed(() => notificationsQueue.value.length > 0);
const currentNotification = computed(() => notificationsQueue.value[0]);
const type = computed(() => currentNotification.value?.type);
const message = computed(() => currentNotification.value?.message);

watch(notification, async (newValue) => {
  if (newValue?.isVisible) {
    notificationsQueue.value.push(newValue); 
  }
});

const removeNotification = () => {
  notificationsQueue.value.shift(); 
  fadeOut.value = false; 
};

watch(currentNotification, async (newValue) => {
  if (newValue) {
    fadeOut.value = false; 
    await nextTick();

    setTimeout(() => {
      fadeOut.value = true; 
      setTimeout(() => {
        removeNotification(); 
      }, 500); 
    }, newValue.duration - 500); 
  }
});

return (_ctx: any,_cache: any) => {
  return (isVisible.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["jq-toast-wrap top-right", [
      { 'fade-out': fadeOut.value },
      { 'success-toast': type.value === 'success', 'error-toast': type.value === 'error' }
    ]])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["jq-toast-single jq-has-icon", { 'jq-icon-success': type.value === 'success', 'jq-icon-error': type.value === 'error' }])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("i", {
              class: _normalizeClass(type.value === 'success' ? 'mdi mdi-check-circle' : 'mdi mdi-close-circle-outline')
            }, null, 2)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, _toDisplayString(type.value === 'success' ? 'Success' : 'Ошибка'), 1),
            _createElementVNode("p", null, _toDisplayString(message.value), 1)
          ])
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}
}

})