<script setup lang="ts">
import { ENavy, router } from '@/router';
import { ITableCellProps } from '@/components/Table/types';
import { ref, watch } from 'vue';
import { getFaqItem, updateFaq, updateFaqAnswer } from '../bff-service';
import ToastMessage from '../components/ToastMessage.vue';
import { showNotification } from './notificationService';

const props = defineProps<ITableCellProps>();
const isModalVisible = ref(false);
const answerText = ref('');
const isAnswerModified = ref(false); 
const initialAnswerText = ref('');
const showSuccessToast = ref(false);
const showErrorToast = ref(false);
const toastMessage = ref('');
const confirmedStatus = ref(Boolean(props.rowData.faq_question_confirmation) || false);

const clickHandler = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
  
  if (props.rowData.id) {
    router.push(ENavy.faqQuestionEdit.replace(':id', props.rowData.id.toString()));
  }
};

const viewAnswer = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  answerText.value = props.rowData.answer || 'В вопросе нет ответа';
  initialAnswerText.value = answerText.value; 
  isModalVisible.value = true;
  isAnswerModified.value = false; 
};

const updateAnswer = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (!answerText.value.trim()) {
    await showNotification('error', 'Ошибка: ответ не может быть пустым', 3000);
    return;
  }

  if (props.rowData.id && answerText.value !== props.rowData.answer) {
    try {
      await updateFaqAnswer(props.rowData.id, { 
        question_text: props.rowData.question_text, 
        answer: answerText.value
      });

      props.rowData.answer = answerText.value;
      initialAnswerText.value = answerText.value; 
      isAnswerModified.value = false;

      await showNotification('success', `Запись ответа в вопросе: "${props.rowData.question_text}", успешно сохранена`, 3000);
    } catch (error: any) {
      await showNotification('error', `Ошибка при сохранении: ${error.requestId || error}`, 3000);
    }
  }
};

const closeModal = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (answerText.value !== initialAnswerText.value) {
    const confirmClose = confirm('Сохранить ответ перед закрытием?');
    if (confirmClose) {
      updateAnswer(new Event('click')).then(() => {
        isModalVisible.value = false;
      });
    } else {
      isModalVisible.value = false;
    }
  } else {
    isModalVisible.value = false;
  }
};

const isUpdating = ref(false); // Флаг блокировки, для ислючения ошибок при быстром переключение чекбокса

const onConfirmedStatusChange = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (isUpdating.value) return; // Если уже идет запрос, выходим
  isUpdating.value = true; 

  try {
    let updatedConfirmation = {
      ...props.rowData.faq_question_confirmation,
      confirmed: confirmedStatus.value
    };

    props.rowData.faq_question_confirmation = updatedConfirmation;

    const response = await updateFaq(props.rowData.id, {
      question_text: props.rowData.question_text,
      answer: props.rowData.answer,
      faq_question_confirmation: updatedConfirmation,
      alternative_question: props.rowData.faq_question_variants,
    });

    if (response) {
      await showNotification('success', `Запись "${props.rowData.question_text}" успешно ${confirmedStatus.value ? 'добавлена в экспорт' : 'добавление в черновик'}`, 3000);
    }
  } catch (error: any) {
    await showNotification('error', `Ошибка при изменении статуса: ${error.requestId || error}`, 3000);
  } finally {
    const updatedFaqData = await fetchFaqData(props.rowData.id);
    if (updatedFaqData) {
      props.rowData.faq_question_confirmation = updatedFaqData;
    }

    isUpdating.value = false; // Снимаем блокировку после завершения запроса
  }
};

const fetchFaqData = async (id: number) => {
  try {
    const response = await getFaqItem(id); 
    return await response.faq_question_confirmation.data;
  } catch (error) {
    console.error('Ошибка при получении обновленных данных:', error);
    return null;
  }
};


watch(() => props.rowData.answer, (newAnswer) => {
  answerText.value = newAnswer || 'В вопросе нет ответа';
});

watch(answerText, () => {
  isAnswerModified.value = true; 
});
</script>

<template>
  <td class="edit-table-cell">
    <div class="edit-table-container">
      <button @click="clickHandler"
        class="btn btn-outline-secondary btn-icon" 
        title="Редактировать" 
      >
        <i class="mdi mdi-pencil"></i>
      </button>
      <button @click="viewAnswer"
        class="btn btn-outline-info btn-icon" 
        title="Просмотр ответа" 
      >
        <i class="mdi mdi-eye"></i>
      </button>
      <label class="switch" :title="confirmedStatus ? 'Опубликован' : 'Черновик'">
        <input type="checkbox" v-model="confirmedStatus" @change="onConfirmedStatusChange" />
        <span class="slider round"></span>
      </label>
    </div>
  </td>

  <ToastMessage type="success" :message="toastMessage" :isVisible="showSuccessToast" />
  <ToastMessage type="error" :message="toastMessage" :isVisible="showErrorToast" />

  <div v-if="isModalVisible" class="modal-overlay-answer">
    <div class="modal-content-answer">
      <h3>{{ props.rowData.question_text }}</h3>
      <div class="textarea__count_char">
        <textarea 
          v-model="answerText" 
          id="faq-answer"
          class="form-control"
          style="resize:vertical"
          required
        />
        <span class="char-counter">Введено символов: {{ answerText.length }}</span>
      </div>
      <div class="modal-buttons">
        <button class="btn btn-success margin-top-faq" @click="updateAnswer">Сохранить</button>
        <button class="btn btn-danger margin-top-faq" @click="closeModal">Закрыть</button>
      </div>
    </div>
  </div>
</template>

<style>
.modal-content-answer .form-control {
  min-height: 300px;
}

.modal-content-answer div {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-overlay-answer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px); 
  z-index: 1000000;
}

.modal-content-answer {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 640px;
  width: 100%;
  z-index: 1001; 
}

.modal-overlay-answer .form-control{
  font-size: 1rem;
}


.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #44ce42;
}

input:focus + .slider {
  box-shadow: 0 0 1px #44ce42;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.edit-table-container{
  display: flex;
  align-items: center;
  column-gap: 5px;
}

</style>
