<script setup lang="ts">
import { ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
// store 
import { useAsyncPriceAllListStore } from '../store/detailsListStore';


const tableStore = useAsyncPriceAllListStore();
// tableStore;

const title = ref<string>('');
const url = ref<string>('');

watch([title, url], ()=>{
    // tableStore.searchBy
    tableStore.filterBy(1, [title.value]);
    tableStore.filterBy(2, [url.value]);
})
</script>

<template>
  <tbody class="cmi-deals-all-filters">
    <tr>
      <td colspan="1">
        Фильтры:
      </td>

      <td>
        <Input
          v-model="title"
          type="search"
        />
      </td>
      <td>
        <Input
          v-model="url"
          type="search"
        />
      </td>
      <td />
    </tr>
  </tbody>
  <tbody
    v-if="tableStore.loading"
    class="cmi-deals-all-filters"
  >
    <tr>
      <td colspan="8">
        Загрузка:
      </td>
    </tr>
  </tbody>
</template>
