<script setup lang="ts">
import { ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
import { useAsyncCategoryAllListStore } from '../store';


const tableStore = useAsyncCategoryAllListStore();
// tableStore;

const name = ref<string>('');
const description = ref<string>('');

watch([name, description], ()=>{
    // tableStore.searchBy
    tableStore.filterBy(1, [name.value]);
    tableStore.filterBy(2, [description.value]);
})
</script>

<template>
  <tbody class="cmi-deals-all-filters">
    <tr>
      <td colspan="1">
        Фильтры:
      </td>
      <td class="faq__table-tag-filter">
        <span class="mdi mdi-magnify"></span>
        <Input
          v-model="name"
          type="search"
        />
      </td>
      <td class="faq__table-tag-filter">
        <span class="mdi mdi-magnify"></span>
        <Input
          v-model="description"
          type="search"
        />
      </td>
      <td />
      <td />
    </tr>
  </tbody>
  <tbody
    v-if="tableStore.loading"
    class="cmi-deals-all-filters"
  >
    <tr>
      <td colspan="8">
        Загрузка:
      </td>
    </tr>
  </tbody>
</template>