<script setup lang="ts">
// components
import { 
  computed, 
  onMounted, 
  onUnmounted, 
  ref, 
  watch, 
  watchEffect
} from 'vue';
import { isUserCanViewFaq, isUserCanEditFaq, useUserStore } from '@/store';
import PageHeader from '@/components/kit/PageHeader.vue';
import Loader from '@/components/Loader.vue';
import Card from '@/components/kit/Card.vue';
import CardTitle from '@/components/kit/CardTitle.vue';
import { routeNames, cmiStatuses } from "@/router";
import { useRoute } from 'vue-router';
import SelectFaq from './components/SelectFaq.vue';
import Row from "@/components/kit/Row.vue";
import FormGroup from "@/components/kit/FormGroup.vue";
// store
import { useFaqEditItemStore } from './store/FaqEditStore';
// api
import {
  createFaq,
  updateFaq,
  deleteFaq,
  getFaqCategories,
  deleteFaqQuestionVariant,
  updateFaqAnswer,
} from './bff-service';
// router
import { ENavy, router } from '@/router';
import { showNotification } from './components/notificationService';
import ToastMessage from './components/ToastMessage.vue';
import defaultUserImage from '@/assets/img/user.svg';


const isDevMode = new URL(window.location.toString()).searchParams.has('_dev'); // Отладочная информация

// store
const store = useFaqEditItemStore();
const pageId = ref<number>(NaN);

const selectedCategory = ref<string[]>([]);
const route = useRoute();
const isCreating = ref(route.query.isCreating === 'true' || false);
const answerLength = computed(() => store.answer.length);
let question_variant_one = ref<string>('');
let question_variant_two = ref<string>('');
let question_variant_one_id = ref<number | null>(null);
let question_variant_two_id = ref<number | null>(null);
const showSuccessToast = ref(false);
const showErrorToast = ref(false);
const toastMessage = ref('');
let confirmedStatus = ref(false);
const user = useUserStore();
let userNameLastUpdate = ref<string>('');
let lastTimeUpdateQuestion = ref<string>('');

// категории и референсы
const categoryList = [] as {
  value: string;
  label: string;
}[];

const fetchData = async () => {
  try {
    // получаем список категорий и референсов 
    const {
      0: resCategories
    } = await Promise.all([
      getFaqCategories()
    ]);

    // заполняем категории
    resCategories.list.map(i=>{
      categoryList.push({
        value: i.id + '',
        label: i.name,
      })
    });

    // устанавливаем флаг загрузки данных страницы
    pageDataLoaded.value = true;
  } catch (error) {
    console.error(error);
    alert('Произошла ошибка, попробуйте перезагрузить страницу.');
  }
};

onMounted(() => {
  fetchData();
  if(!isCreating.value){
    const route = useRoute();
    const pageID = parseInt((route?.params?.id ?? '') + '', 10);
    if (Number.isInteger(pageID)) {
      pageId.value = pageID;
      store.getDataById(pageID);
    }
  }
});

onUnmounted(() => {
  if (!isCreating.value) {
    store.$reset();
  }
});


// ожидание загрузки страницы и всех данных
const pageLoaded = ref<boolean>(false);
const pageDataLoaded = ref<boolean>(false);

watch(
  [
    pageDataLoaded,
    ()=>store.$loading
  ],

  ({0: dataLoaded, 1: storeLoading})=>{

    if (dataLoaded && (!storeLoading || isCreating.value)) {
     
      // подготавливаем категории
      var faq_categories = store.faq_categories;
      if (Array.isArray(faq_categories)) {
        selectedCategory.value = Array.from(new Set([ 
          ...selectedCategory.value,
          ...faq_categories.map(i => i.id + '')
        ]));
      }

      // подготавливаем альтернативные варианты вопроса
      var faq_question_variants = store.faq_question_variants; 
      if (faq_question_variants.length > 0) {
        question_variant_one.value = faq_question_variants[0]?.attributes.variant_text;
        question_variant_one_id.value = faq_question_variants[0]?.id;
        question_variant_two.value = faq_question_variants[1]?.attributes.variant_text;
        question_variant_two_id.value = faq_question_variants[1]?.id;
      }

      confirmedStatus = ref(Boolean(store.faq_question_confirmation.data?.id) || false);

      // Подготовка времени и имени кто менял вопрос
      var faq_question_versions = store.faq_question_versions;
      if(faq_question_versions.length) {
        lastTimeUpdateQuestion.value = faq_question_versions[0]?.attributes.createdAt ?? "изменений не было";
        userNameLastUpdate =  faq_question_versions[0]?.attributes.users_permissions_user?.data?.attributes?.username ?? "";
      }

      pageLoaded.value = true
    }
  }
);

const lastTime = computed(() => {
  if (!lastTimeUpdateQuestion.value) return "Нет данных";

  const date = new Date(lastTimeUpdateQuestion.value);

  if (isNaN(date.getTime())) return "Не было изменений";

  return new Intl.DateTimeFormat("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Moscow",
  }).format(date);
});

// сохранение и добавление
const submitFormHandler = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  // Если нет изменений то просто переходим на страницу вопросов
  if (!store.isUpdated) {
    router.push(ENavy.faqQuestions);
		return;
	}

  const requestData: any = {
    question_text: store.question_text,
    answer: store.answer,
    categories: [...selectedCategory.value].map(Number),
    alternative_question: [
      { id: question_variant_one_id.value, variant_text: question_variant_one.value },
      { id: question_variant_two_id.value, variant_text: question_variant_two.value }
    ],
  };

  requestData.faq_question_confirmation = {
    id: store.faq_question_confirmation.data?.id,
    confirmed: String(confirmedStatus.value),
  };

  const requestPromise = isCreating.value ? createFaq(requestData) : updateFaq(pageId.value, requestData);

	requestPromise
		.then((response) => {
			if (response.status) {
				showNotification('success', `Вопрос ${store.question_text} успешно ${isCreating.value ? 'создан' : 'обновлён'}.`, 3000);
				router.push(ENavy.faqQuestions);
				store.isUpdated = false; // Сбрасываем флаг после успешного сохранения
			} else {
				showNotification('error', 'Произошла ошибка, попробуйте еще раз.', 3000);
			}
		})
		.catch((e) => {
			console.error(e);
			showNotification('error', `Ошибка ${e.requestId}.`, 3000);
		});
};

// удаление
const deleteButtonHandler = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (!pageId.value) {
    return;
  }

  const isApproved = confirm('Удалить вопрос?');
  if (!isApproved) {
    return;
  }

  try {
    // Проверяем, есть ли альтернативные вопросы
    const variants = store.faq_question_variants;

    if (variants && variants.length > 0) {
      // Удаляем каждый альтернативный вопрос
      for (const variant of variants) {
        await deleteFaqQuestionVariant(variant.id);
      }
    }

    // Удаляем основной вопрос
    const response = await deleteFaq(pageId.value);

    if (response.status) {
      router.push(ENavy.faqQuestions);
      showNotification('success', `Вопрос ${store.question_text} успешно удалён.`, 3000);
    } else {
      showNotification('error', `Произошла ошибка, попробуйте еще раз.`, 3000);
    }
  } catch (err) {
    showNotification('error', `Ошибка ${err}.`, 3000);
  }
};

</script>

<template>
  <PageHeader>Редактирование FAQ</PageHeader>

  <Card v-if="!isUserCanViewFaq()" style="position:relative">
    <CardTitle>Нет прав на просмотр данных</CardTitle>
  </Card>

  <Card v-else-if="isUserCanEditFaq() && (pageId || isCreating)" style="position:relative">
    <Loader v-if="!pageLoaded" />

    <template v-else-if="store.$error">
      <h3>Произошла ошибка получения данных.</h3>
      <p><strong>Попробуйте перезагрузить страницу</strong>, если это не поможет <strong>обратитесь в
          поддержку</strong>.</p>
      <p><strong>Данные об ошибке.</strong></p>
      <p><strong>Обозначение ошибки: </strong>{{ store.$error.error }}</p>
      <p><strong>ID запроса: </strong>{{ store.$error.requestId }}</p>
      <p><strong>Код ответа: </strong>{{ store.$error.code ?? '' }}</p>
      <p>&nbsp;</p>
      <router-link :to="{ name: routeNames.faqQuestions, params: { status: cmiStatuses.all } }">Вернуться к списку
        вопросов.</router-link>
    </template>
    <template v-else-if="pageLoaded">
      <template v-if="isDevMode">
        <p>только для отладки:</p>
        <textarea :value="JSON.stringify(store, null, `\t`)" class="form-control form-control-sm"
          style="resize:vertical" />
        <p></p>
      </template>
      <div class="container-button-avatar">
        <CardTitle>Редактирование данных</CardTitle>
        <div v-if=!isCreating class="change-info-container">
          <div data-v-61dd7a3d="" class="nav-profile-img">              
            <img
              style="width: 32px;"
              :alt="user.name"
              :src="user.avatar || defaultUserImage"
            >
          </div>
          <div class="change-info">
            <p class="change-info-p">Изменено: {{ lastTime }} </p>
            <p>{{ userNameLastUpdate }}</p>
          </div>
        </div>
      </div>
      <form @submit="submitFormHandler">
      <FormGroup>
        <Row class="tab-content tab-transparent-content">
          <div class="col-lg-6 textarea__faq-question">
            <div class="select-category faq-select">
              <label class="key-label" for="category-list">Категория</label>
              <SelectFaq
                v-if="pageDataLoaded"
                id="category-list"
                :options="categoryList" 
                :multiple="true"
                v-model="selectedCategory"
                @update:modelValue="store.setUpdated()"
              >
              
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!selectedCategory.length"
                    v-bind="attributes"
                    v-on="events"
                    @input="store.setUpdated()"
                  />
                </template>
              </SelectFaq>
            </div>
            <div class=" textarea__faq-question margin-top-faq">
              <label class="key-label margin-top-faq" for="faq-question">Вопрос</label>
              <textarea 
                v-model="store.question_text"
                id="faq-question"
                class="form-control form-control-sm"
                style="resize:vertical"
                required
                @input="store.setUpdated()"
              />
            </div>
            <div>
              <div class=" textarea__faq-question margin-top-faq">
                <label class="key-label" for="faq-question">Альтернативный вопрос 1</label>
                <textarea 
                  v-model="question_variant_one"
                  id="faq-answer" 
                  @input="store.setUpdated()"
                  class="form-control form-control-sm" 
                  style="resize: vertical;"
                />
              </div>
              <div class=" textarea__faq-question margin-top-faq">
                <label class="key-label" for="faq-question">Альтернативный вопрос 2</label>
                <textarea 
                  v-model="question_variant_two"
                  id="faq-answer" 
                  @input="store.setUpdated()"
                  class="form-control form-control-sm" 
                  style="resize: vertical;"/>
              </div>
            </div>
          </div>

          <div class="col-lg-6 textarea__faq-answer margin-top">
            <div class="textarea__count_char">
              <label class="key-label" for="faq-answer">Ответ</label>
              <textarea 
                v-model="store.answer" 
                id="faq-answer"
                class="form-control form-control-sm"
                style="resize:vertical"
                required
                @input="store.setUpdated()"
              />
            <span class="char-counter">Введено символов: {{ answerLength }}</span>
            </div>
            <div class="export-checkbox-container margin-top-faq">
              <label class="key-label" for="faq-answer">Экспорт</label>
              <label class="switch" :title="confirmedStatus ? 'Опубликован' : 'Черновик'">
                <input type="checkbox" @input="store.setUpdated()" v-model="confirmedStatus"/>
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </Row>
        <button v-if="isCreating" class="btn btn-success btn-fw margin-top-faq">Сохранить</button> 
        <button v-else class="btn btn-success btn-fw margin-top-faq">Сохранить изменения</button> 
      </FormGroup>
      </form>

      <FormGroup v-if="!isCreating">
        <button 
          type="button" 
          class="btn btn-danger btn-icon-text faq-btn-danger"
          @click="deleteButtonHandler"
        >
          <i class="fa fa-trash-o"></i> Удалить вопрос
        </button>
      </FormGroup>

      <ToastMessage type="success" :message="toastMessage" :isVisible="showSuccessToast" />
      <ToastMessage type="error" :message="toastMessage" :isVisible="showErrorToast" />
    </template>
  </Card>
</template>
  

<style lang="scss">
.container-button-avatar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.change-info-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  color: #000;
  column-gap: 10px;
}

.change-info p {
  margin: 0;
  font-size: 14px;
}

.change-info-p{
  color: rgb(167, 175, 183);
}

.change-info {
  display: flex;
  flex-direction: column;
}

.export-checkbox-container{
  display: flex;
  flex-direction: column;
}

.textarea__count_char {
  position: relative; 
}

textarea {
  width: 100%;
  min-height: 100px;
  box-sizing: border-box; 
}

.char-counter {
  position: absolute; 
  bottom: 8px; 
  right: 8px; 
  font-size: 16px; 
  color: #6c757d; 
}

.reference-row {
  display: none;
  gap: 10px;
  align-items: start;
}

.reference-type-select .vs__clear{
  display: none;
}

.reference-type-select{
  flex: 1;
  min-width: 130px;
}

.reference-select {
  flex: 4;
}

.select-category .vs__selected{
  padding: 6px 10px;
  font-size: 18px;
  border: solid 1px #000;
}

.select-reference .vs__selected{
  padding: 6px 10px;
  font-size: 18px;
  border: solid 1px #a461d8;
}

.margin-top-faq{
  margin-top: 14px;
}
.textarea__faq-question .form-control-sm{
  font-size: 20px;
  min-height: 80px;
}
.textarea__faq-answer .form-control-sm{
  font-size: 20px;
  height: 227px;
}

.channel-form input::placeholder{
    opacity: 0.6;
}

.title-price {
    margin-bottom: 1rem;
}

.--visible-row {
    margin-bottom: 1rem;
    display: initial !important;
}

.--hidden-row {
    display: none;
}

/* faq select */
.faq-select .vs__selected {
    text-align: left;
    text-align: left;
    font-size: 14px;
    line-height: 1.2em;
}

.vs__dropdown-option--selected {
  display: none;
}


.faq-reference-select {
  .vs__dropdown-option {
    padding: 0;
  }

  .faq-ref-option {
    padding: 10px;
  }

  .faq-ref-option::before, .faq-ref-selected-option::before {
    display: inline-block;
    font: normal normal normal 24px / 1 "Material Design Icons";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 5px;
  }

  .faq-ref-VIDEO::before {
    content: "\F567";
  }
  .faq-ref-LINK::before {
    content: "\F339";
  }
  .faq-ref-SERVICE::before {
    content: "\F1B1";
  }

  input[type="search"] {
    min-width: 50px;
  }
}

.textarea__faq-answer .form-control-sm,
.textarea__faq-question .form-control-sm {
    font-size: 15px;
    line-height: 1.1em;
    letter-spacing: 0.1px;
}

.faq-reference-select .vs__dropdown-menu{
  max-height: 220px;
  overflow-x: hidden;
}

.faq-reference-select .vs__dropdown-option{
  white-space: normal;
}

.faq-btn-danger{
  margin-bottom: 40px;
}
</style>
