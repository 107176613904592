import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "edit-table-cell" }
const _hoisted_2 = { class: "edit-table-container" }
const _hoisted_3 = {
  key: 1,
  class: "d-flex gap-2 align-items-center"
}

import { ref } from 'vue';
import { TCategoryData, useAsyncCategoryAllListStore } from '../store'; // Подключите правильный путь
import { deleteCategory, getJsonCategory, updateCategory } from '../bff-service';


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryEditTable',
  props: {
    rowData: {}
  },
  setup(__props: any) {

const tableStore = useAsyncCategoryAllListStore();
const props = __props;
const { rowData } = props;
const name = ref(props.rowData.name);
const idCategory = ref(props.rowData.id);
const description = ref(rowData.description);

const applyEditing = async (row: TCategoryData) => {
  try {
    await updateCategory(row.id, { name: row.name, description: row.description });
    await tableStore.getData();
    row.isEditing = false; 
  } catch (error) {
    console.error('Ошибка при обновлении категории', error);
  }
};

const cancelEditing = (row: TCategoryData) => {
  row.isEditing = false;
  row.name = name.value;
  row.description = description.value;
};

const deleteCategoryById = () => {

var isApproved = window.confirm(`Удалить категорию "${props.rowData.name}?`);
if (!isApproved) { return }

deleteCategory(props.rowData.id)
  .then(()=>{
    tableStore.getData()
  })
  .catch((e)=>{
    alert(`Ошибка удаления категории.\n${e.error}\nRequest ID: ${e.requestId}`)
  })
}

const exportJsonCategory = async () => {
  try {
    const jsonData = await getJsonCategory(props.rowData.id);
    const jsonString = JSON.stringify(jsonData.data, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${props.rowData.name}.json`; 
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Ошибка при экспорте категории", error);
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("td", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_unref(rowData).isEditing)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-outline-secondary btn-icon",
            title: "Редактировать",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(rowData).isEditing = !_unref(rowData).isEditing))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "mdi mdi-pencil" }, null, -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "btn btn-outline-success btn-icon",
              title: "Применить редактирование",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (applyEditing(_unref(rowData))))
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("i", { class: "mdi mdi-check" }, null, -1)
            ])),
            _createElementVNode("button", {
              class: "btn btn-outline-primary btn-icon",
              title: "Отменить редактирование",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (cancelEditing(_unref(rowData))))
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("i", { class: "mdi mdi-close" }, null, -1)
            ])),
            _createElementVNode("button", {
              class: "btn btn-outline-danger btn-icon",
              title: "Удалить",
              onClick: deleteCategoryById
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("i", { class: "fa fa-trash-o" }, null, -1)
            ]))
          ])),
      (!_unref(rowData).isEditing)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "btn btn-outline-success btn-icon",
            title: "Экспортировать",
            onClick: exportJsonCategory
          }, _cache[7] || (_cache[7] = [
            _createElementVNode("img", {
              class: "json-export-icon",
              src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABbElEQVR4nM2TPUsDQRCGL4VYCRJRCwstbCys0kgQjvnIeVj4GywsBQsLOwURtdJe/BP+A5FgEDJzKiGFWNhZBEsVLTzZmBg9Itm9HOrAC/s1PDvvznpeIlgxthUp7Huu4QLgNJB2ostFyAWSBsAukLQAtoX0A2CLPGuAU55r57CF/hbgtdcEt1l4phTh8scenAUCBSMSPG2fJYWdb3nWgCrPsmCNFbdI4CUQf5oUK6xwbsafgAsaYcV7ZwAJlg2EBI+a67GXY8E3o+a4c3aNFVd6AkjhkBVvw5twsGXRKis2MMIJVnzyI3+YFOqscIXXOP4F0AhqQd6mgs1iuThkLGnN1xcif4oVFlvzg9JlaSzU+VEWOE681561RQk9J37ro6nmf7VpFj/ZOn4NwN0kpkVhqa+L9fKWBB5AYDI1wCliL0eKJ10q3c2MEdSCPCveddoYK4VqYSAzgAmu+nOs+PqTfdlABDdIKExuvANwV2Mw4iONaAAAAABJRU5ErkJggg==",
              alt: "json-download"
            }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})