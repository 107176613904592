import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-lg-6" }
const _hoisted_2 = {
  key: 0,
  href: "/faq-create/?isCreating=true"
}

import { ref, defineComponent, onMounted } from 'vue';
// components
import {
  Card,
  CardTitle,
  Row,
  MinWidthAndScroll,
  Table,
  TablePagination,
} from '@/components/kit';
import { EmptyTr } from './components';
import { isUserCanEditFaq, isUserCanViewFaq } from '@/store';
import AllFaqQuestionsFilters from './components/AllFaqQuestionsFilters.vue';
import FaqQuestionEdit from './components/FaqQuestionEdit.vue';
import QuestionTdTable from './components/QuestionTd.vue';
import CategoryTd from './components/CategoryTd.vue';
// store
import { useAsyncFaqListStore } from './store'; 



export default /*@__PURE__*/_defineComponent({
  __name: 'FaqQuestionsList',
  setup(__props) {

const tableStore = useAsyncFaqListStore(); 

tableStore.columns = [
  {
    key: 'id',
    title: 'ID',
    searchable: true,
    filterable: true,
  },
  {
    key: 'category',
    title: 'Категория',
    searchable: true,
    filterable: true,
    component: CategoryTd
  },
  {
    key: 'question_text',
    title: 'Вопрос',
    searchable: true,
    filterable: true,
    component: QuestionTdTable,
  },
];

if (isUserCanEditFaq()) {
  tableStore.columns.push({
    key: 'confirmed',
    title: 'Редактирование',
    searchable: true,
    filterable: true,
    component: FaqQuestionEdit,
  });
}

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}

onMounted(() => {
  tableStore.clearFilters(); 
  tableStore.getData(); 
});

defineComponent({ name: 'AllFaqTable' });

return (_ctx: any,_cache: any) => {
  return (!_unref(isUserCanViewFaq)())
    ? (_openBlock(), _createBlock(_unref(Card), {
        key: 0,
        style: {"position":"relative"}
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(CardTitle), null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Нет прав на просмотр данных")
            ])),
            _: 1
          })
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_unref(Card), {
        key: 1,
        style: {"position":"relative"}
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(CardTitle), null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Список FAQ")
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", null, [
              (_unref(isUserCanEditFaq)())
                ? (_openBlock(), _createElementBlock("a", _hoisted_2, _cache[2] || (_cache[2] = [
                    _createElementVNode("button", { class: "btn btn-inverse-primary btn-fw" }, " Добавить вопрос ", -1)
                  ])))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(_unref(Row), null, {
            default: _withCtx(() => [
              _createVNode(_unref(MinWidthAndScroll), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(Table), { "use-store": _unref(useAsyncFaqListStore) }, {
                    default: _withCtx(() => [
                      _createVNode(AllFaqQuestionsFilters)
                    ]),
                    _: 1
                  }, 8, ["use-store"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(TablePagination), {
            class: "tab-content tab-transparent-content",
            "use-store": _unref(useAsyncFaqListStore)
          }, null, 8, ["use-store"])
        ]),
        _: 1
      }))
}
}

})