import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-lg-6"
}
const _hoisted_2 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = ["disabled"]

import { ref, watch } from 'vue';
import { defineComponent } from 'vue';
// import { createModerator, getList } from './bff-service'; // Импортируем функцию из BFF-сервиса.
// components
import {
    Card,
    CardTitle,
    MinWidthAndScroll,
    Table,
    TablePagination
} from '@/components/kit';
import { EmptyTr, Row as TableRow } from './components';
import AllCategoriesFilters from './components/AllCategoriesFilters.vue';
// store
import { useAsyncCategoryAllListStore } from './store';
import { isUserCanCategories, isUserCanEditCategories } from '@/store';
import CategoryEditTable from './components/CategoryEditTable.vue';
import { createCategory, getFaqCategory } from './bff-service';
import InputCategoryDesc from './components/InputCategoryDesc.vue';
import InputCategoryName from './components/InputCategoryName.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqCategory',
  setup(__props) {

const tableStore = useAsyncCategoryAllListStore();

tableStore.columns = [
  { key: 'id', title: '#' },
  { key: 'name', title: 'Категория', searchable: true, filterable: true, component: InputCategoryName },
  { key: 'description', title: 'Описание', searchable: true, filterable: true, component: InputCategoryDesc },
];

if(isUserCanEditCategories()){
  tableStore.columns.push({ key: 'edit', title: 'Редактировать', component: CategoryEditTable });
}

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}
tableStore.row.component = TableRow;

if (isUserCanCategories()) {
    tableStore.getData();
}

const isCreateCategoryVisible = ref(false);

// данные в инпутах при добавление модера
const newCategory = ref({
    name: '',
    description: '',
});

const errorMessages = ref({
    name: '',
});

const categories = ref<string[]>([]);

watch(
  () => tableStore.data, // При загрузке data не успевает подгружаться, следим за обновлением
  (newData) => {
    categories.value = newData.map((category) => category.name);
  },
  { immediate: true } 
);

const validateFields = async () => {
  const errors = {
    name: '',
  };

  const responseByName = await getFaqCategory(1, 10, { name: newCategory.value.name, description: '' });
  if (responseByName && responseByName.list.some(item => item.name.toLocaleLowerCase() === newCategory.value.name.toLocaleLowerCase())){
    errors.name = 'Категория с таким именем уже есть';
  }

  errorMessages.value = errors;

  return !errors.name;
};

const createNewCategory = async () => {
    
    const isValid = await validateFields();
    if(!isValid) return;

    const response = await createCategory(newCategory.value);
    if(response){
      newCategory.value = {
            name: '', 
            description: '',
        };
        isCreateCategoryVisible.value = false;
        tableStore.getData(); 
    } 
};

defineComponent({ name: 'AllCategoryTable' });

return (_ctx: any,_cache: any) => {
  const _component_Row = _resolveComponent("Row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(isUserCanCategories)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isUserCanCategories)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 1,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Категории FAQ")
              ])),
              _: 1
            }),
            (_unref(isUserCanEditCategories)())
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("p", null, [
                    _createElementVNode("button", {
                      class: "btn btn-inverse-primary btn-fw",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (isCreateCategoryVisible.value = !isCreateCategoryVisible.value))
                    }, " Добавить категорию ")
                  ]),
                  (isCreateCategoryVisible.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "telegram_user_id" }, "Наименование категории", -1)),
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            id: "telegram_user_id",
                            class: "form-control",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newCategory.value.name) = $event)),
                            placeholder: "Введите название"
                          }, null, 512), [
                            [
                              _vModelText,
                              newCategory.value.name,
                              void 0,
                              { trim: true }
                            ]
                          ]),
                          (errorMessages.value.name)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(errorMessages.value.name), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _cache[6] || (_cache[6] = _createElementVNode("label", { for: "description" }, "Описание категории", -1)),
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            id: "description",
                            class: "form-control",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((newCategory.value.description) = $event)),
                            placeholder: "Введите описание"
                          }, null, 512), [
                            [
                              _vModelText,
                              newCategory.value.description,
                              void 0,
                              { trim: true }
                            ]
                          ])
                        ]),
                        _createElementVNode("button", {
                          class: "btn btn-success",
                          onClick: createNewCategory,
                          disabled: !newCategory.value.name.trim() || !newCategory.value.description.trim()
                        }, " Создать ", 8, _hoisted_6)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Row, null, {
              default: _withCtx(() => [
                _createVNode(_unref(MinWidthAndScroll), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Table), { "use-store": _unref(useAsyncCategoryAllListStore) }, {
                      default: _withCtx(() => [
                        _createVNode(AllCategoriesFilters)
                      ]),
                      _: 1
                    }, 8, ["use-store"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(TablePagination), {
              class: "tab-content tab-transparent-content",
              "use-store": _unref(useAsyncCategoryAllListStore)
            }, null, 8, ["use-store"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})