<script setup lang="ts">
import { ref, watch } from 'vue';
// components
import Input from '@/components/kit/Input.vue';
import { useAsyncChannelAllListStore } from '../store';


const tableStore = useAsyncChannelAllListStore();
// tableStore;

const channel_id = ref<string>('');
const name = ref<string>('');

watch([channel_id, name], ()=>{
    // tableStore.searchBy
    tableStore.filterBy(1, [channel_id.value]);
    tableStore.filterBy(2, [name.value]);
})
</script>

<template>
  <tbody class="cmi-deals-all-filters">
    <tr>
      <td colspan="1">
        Фильтры:
      </td>

      <td>
        <Input
          v-model="channel_id"
          type="search"
        />
      </td>
      <td>
        <Input
          v-model="name"
          type="search"
        />
      </td>
      <td />
    </tr>
  </tbody>
  <tbody
    v-if="tableStore.loading"
    class="cmi-deals-all-filters"
  >
    <tr>
      <td colspan="8">
        Загрузка:
      </td>
    </tr>
  </tbody>
</template>
