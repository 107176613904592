import { ref } from 'vue';

export type NotificationType = 'success' | 'error';

export interface INotification {
  type: NotificationType;
  message: string;
  isVisible: boolean;
  duration: number;
}

const notification = ref<INotification | null>(null);

export function showNotification(type: NotificationType, message: string, duration: number) {
  notification.value = {
    type,
    message,
    isVisible: true,
    duration
  };

  setTimeout(() => {
    notification.value = null;
  }, duration);
}

export function useNotification() {
  return {
    notification
  };
}
