import {
  generateAsyncDataTableStore,
  definePromiseQueue,
  mapTableFiltersToSearchParams,
} from '@/components/Table';
import { getFaqCategory, TCategoriesSearchParams } from '../bff-service';
import { TCategoriesGetListResDto } from '../types/dto';

export type TCategoryData = {
  id: number;
  name: string;
  description: string;
  isEditing: boolean; // Редактирование в строке таблицы
  isCheckbox: boolean;
}

const allListPromiseQueue = definePromiseQueue<TCategoriesGetListResDto>();

export const useAsyncCategoryAllListStore = generateAsyncDataTableStore<TCategoryData>({
  initialState: {
      loading: true,
  },

  dataGetter: async (useStore) => {
    const store = useStore();

    await allListPromiseQueue.wait(allListGetter, useStore)
      .then(response=>{
        store.data = response.list.map( deal => {
          return {
            id: deal.id,
            name: deal.name,
            description: deal.description,
            isEditing: false,
            isCheckbox: false,
          };
        });
        store.paginateTotal = response.paginate.filtered;
        store.paginateFiltered = response.paginate.filtered;
      })
      .catch(e=>{
        store.data = [];
        store.paginateTotal = 0;
      })
      .finally(()=>{
        store.loading = false;
      })
  }
});


// получение списка заявок
const allListGetter = (): Promise<TCategoriesGetListResDto> => {
  const store = useAsyncCategoryAllListStore();
  
  // приводим данные фильтров стора таблиц 
  // к виду данных для поиска
  const search = mapTableFiltersToSearchParams<typeof store, TCategoriesSearchParams>(
    store, // указываем нужный стор
    // указываем данные поиска по дефолту
    {
      name: '',
      description: '',
    },
    // указываем соответствие ключей строра таблиц к ключам данных для поиска
    {
      name: 'name',
      description: 'description',
    }
  );
  return getFaqCategory(store.paginatePage, store.paginatePerPage, search);
}