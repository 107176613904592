<script setup lang="ts">
import { ref, defineComponent, onMounted } from 'vue';
// components
import {
  Card,
  CardTitle,
  Row,
  MinWidthAndScroll,
  Table,
  TablePagination,
} from '@/components/kit';
import { EmptyTr } from './components';
import { isUserCanEditFaq, isUserCanViewFaq } from '@/store';
import AllFaqQuestionsFilters from './components/AllFaqQuestionsFilters.vue';
import FaqQuestionEdit from './components/FaqQuestionEdit.vue';
import QuestionTdTable from './components/QuestionTd.vue';
import CategoryTd from './components/CategoryTd.vue';
// store
import { useAsyncFaqListStore } from './store'; 


const tableStore = useAsyncFaqListStore(); 

tableStore.columns = [
  {
    key: 'id',
    title: 'ID',
    searchable: true,
    filterable: true,
  },
  {
    key: 'category',
    title: 'Категория',
    searchable: true,
    filterable: true,
    component: CategoryTd
  },
  {
    key: 'question_text',
    title: 'Вопрос',
    searchable: true,
    filterable: true,
    component: QuestionTdTable,
  },
];

if (isUserCanEditFaq()) {
  tableStore.columns.push({
    key: 'confirmed',
    title: 'Редактирование',
    searchable: true,
    filterable: true,
    component: FaqQuestionEdit,
  });
}

tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTr };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}

onMounted(() => {
  tableStore.clearFilters(); 
  tableStore.getData(); 
});

defineComponent({ name: 'AllFaqTable' });
</script>


<template>
  <Card v-if="!isUserCanViewFaq()" style="position:relative">
    <CardTitle>Нет прав на просмотр данных</CardTitle>
  </Card>
  <Card v-else style="position:relative">
    <CardTitle>Список FAQ</CardTitle>
    <div class="col-lg-6">
      <p>
        <a v-if="isUserCanEditFaq()" href="/faq-create/?isCreating=true">
          <button class="btn btn-inverse-primary btn-fw">
            Добавить вопрос
          </button>
        </a>
      </p>
    </div>
    <Row>
      <MinWidthAndScroll>
        <Table :use-store="useAsyncFaqListStore">
          <AllFaqQuestionsFilters />
        </Table>
      </MinWidthAndScroll>
    </Row>

    <TablePagination class="tab-content tab-transparent-content" :use-store="useAsyncFaqListStore" />
  </Card>
</template>
