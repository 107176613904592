import { createRouter, createWebHistory, viewDepthKey } from 'vue-router';
// components
import { LoginPage } from '@/modules/login';
import Cmi from '@/modules/cmi/CmiList.vue';
import PriceEdit from '@/modules/prices/PriceEdit.vue';
import PriceList from '@/modules/prices/PriceList.vue';
import NotFound from '@/components/NotFound.vue';
import Dashboard from '@/components/Dashboard.vue';
import PermissionsPage from '@/modules/userPermissions/PermissionsPage.vue';
import UserListPage from '@/modules/userPermissions/UserListPage.vue';
import UserPage from '@/modules/userPermissions/UserPage.vue';
import CmiDealViewerEditor from '@/modules/cmi/CmiDealViewerEditor.vue';
import PostPage from '@/modules/cmi/PostPage.vue';
import PostViewerEditorPage from '@/modules/cmi/PostViewerEditorPage.vue';
import DictionaryEdit from '@/modules/tgAdminPanel/DictionaryEdit.vue';
import ModeratorsEdit from '@/modules/tgAdminPanel/ModeratorsEdit.vue';
import ChannelsList from '@/modules/tgAdminPanel/ChannelsList.vue';
import FaqQuestionsList from '@/modules/faq/FaqQuestionsList.vue';
import DevKit from '@/components/kit/KIT.vue';
import FaqQuestion from '@/modules/faq/FaqQuestion.vue';
import FaqCategory from '@/modules/faq/FaqCategory.vue';
import FaqCreate from '@/modules/faq/FaqCreate.vue';
import BlackList from '@/modules/tgAdminPanel/BlackList.vue';
// user store
import {
	useUserStore,
	useNavyStore,
	isUserAuth,
} from '@/store';
// consts
import { defaultPageTitle } from './consts';
// dev / prod mode
const devMode = process?.env?.NODE_ENV === "development";


// роуты
export const routeNames = {
	// для KIT во время разработки
	...( devMode ? { kit: 'kit' } : {}),
	// роуты приложения
	dashboard: 'dashboard',
	// cmi
	cmi: 'cmi',
	cmiEditor: 'cmiEditor',
	cmiPost: 'cmiPost',
	cmiPostEditor: 'cmiPostEditor',
	// price edit
	prices: 'prices',
	priceEditPage: 'priceEditPage',

	// Admin panel
	channels: 'channels',
	channelsEdit: 'channelsEditPage',

	dictionary: 'dictionary',

	moderators: 'moderators',

	// FAQ
	faqQuestions: 'faqQuestions',
	faqQuestionEdit: 'faqQuestionEditPage',
	faqQuestionCreate: 'faqQuestionCreate',
	faqCategory: 'faqCategory',

	admin: 'admin',
	userRulesAdministration: 'userRulesAdministration',
	userAdministration: 'userAdministration',
	userPage: 'userPage',
	userEditPage: 'userEditPage',
	login: 'login',
} as const;

// все ключи роутов
export type TRoutes = keyof typeof routeNames;

// список прав на действия
export const EPermissions = {
	// admin
	viewPermission: 10,
	editPermission: 11,
	viewUsers: 20,
	editUsers: 21, 

	// other
	viewCmi: 2,
	editCmi: 3,
	postCmi: 4,
	viewPrices: 5,
	editSitePagesPrice: 5, 
	viewChannels: 6,
	editChannels: 7,
	viewDictionary: 8,
	editDictionary: 9,
	KeysTgChannel: 10, // Ключи в тг канале
	editModerators: 11, 
	faqQuestions: 13,
	faqQuestionEdit: 14,
	faqCategory: 13,
	faqCategoryEdit: 15,

} as const;

// права на роуты
export const routesPermissions: Record<TRoutes, number[]> = {
	kit: [],
	dashboard: [],

	// login
	login: [],

	// admin
	admin: [ EPermissions.viewPermission, EPermissions.editPermission, EPermissions.viewUsers, EPermissions.editUsers ],
	userRulesAdministration: [ EPermissions.viewPermission, EPermissions.editPermission ],
	userAdministration: [ EPermissions.viewUsers, EPermissions.editUsers ],
	userPage: [ EPermissions.viewUsers ],
	userEditPage: [ EPermissions.editUsers ],

	// cmi
	cmi: [ EPermissions.viewCmi, EPermissions.editCmi ],
	cmiEditor: [ EPermissions.viewCmi, EPermissions.editCmi ],
	cmiPost: [ EPermissions.postCmi, ],
	cmiPostEditor: [ EPermissions.postCmi, ],

	// price edit
	prices: [ EPermissions.viewPrices, EPermissions.editSitePagesPrice],
	priceEditPage: [ EPermissions.viewPrices, EPermissions.editSitePagesPrice],

	// ADMIN PANEL
	// Channel
	channels: [ EPermissions.viewChannels, EPermissions.editChannels],  
	channelsEdit: [ EPermissions.viewChannels, EPermissions.editChannels], 

	// dictionaries
	dictionary: [ EPermissions.viewDictionary, EPermissions.editDictionary], 

	// Moderator
	moderators: [ EPermissions.editModerators], 

	// FAQ
	faqQuestions: [ EPermissions.faqQuestions, EPermissions.faqQuestionEdit ], 
	faqQuestionEdit: [ EPermissions.faqQuestions, EPermissions.faqQuestionEdit ], 
	faqQuestionCreate: [EPermissions.faqQuestions, EPermissions.faqQuestionEdit ],
	faqCategory: [ EPermissions.faqCategory],

};

// не юзаем enum, чтобы было можно юзать в шаблонах
export const ENavy: Record<TRoutes, string> = {
	kit: '/kit',
	dashboard: '/dashboard',

	// login
	login: '/login',
    
	// admin
	admin: '/admin', // admin of erp / администрирование erp
	userRulesAdministration: '/admin/rules', // admin of erp / администрирование erp
	userAdministration: '/admin/users', // admin of erp / администрирование erp
	userPage: '/user/:userId',
	userEditPage: '/edit-user/:userId',

	// cmi
	cmi: '/cmi/list/:status', // compulsory medical insurance - обязательное медицинское страхование
	cmiEditor: '/cmi/deal/:id', // просмотрщик и редактор заявки ОМС
	cmiPost: '/cmi/post-list',
	cmiPostEditor: '/cmi/post/:id',

	// price edit
	prices: '/prices/',
	priceEditPage: '/prices-edit-page/:id',

	// admin panel
	channels: '/channels/',
	channelsEdit: '/channels-edit/:id',
	
	dictionary: '/dictionary/:id',

	moderators: '/moderators/',

	// FAQ
	faqQuestions: '/faq-questions/',
	faqQuestionEdit: '/faq-questions-edit/:id',
	faqQuestionCreate: '/faq-create/',
	// FAQ category
	faqCategory: '/faq-category/',

	// reviews
	// reviews: '/reviews', // reviews on the forum - отзывы на форуме
};

export type TNavyRoute = typeof ENavy[keyof typeof ENavy];

export const router = createRouter({
	history: createWebHistory(),
	routes: [
		// Главная
		{
			// для удобства добавлен путь /
			path: '/',
			component: Dashboard,
			meta: {
				permissions: routesPermissions.dashboard 
			}
		},

		{
			name: routeNames.dashboard,
			path: ENavy.dashboard,
			component: Dashboard,
			meta: {
				permissions: routesPermissions.dashboard 
			}
		},

		// PriceList
		{
			name: 'prices',
			path: ENavy.prices,
			component: PriceList,
			props: true,
			meta: {
				permissions: routesPermissions.prices,
				pageTitle: 'Список цен - ' + defaultPageTitle, 
			}
		},

		// Price Edit
		{
			name: 'prices-editor',
			path: ENavy.priceEditPage,
			component: PriceEdit,
			props: true,
			meta: {
				permissions: routesPermissions.priceEditPage,
				pageTitle: 'Изменение цены - ' + defaultPageTitle, 
			}
		},

		// Channels list & edit
		{
			name: 'channels',
			path: ENavy.channels,
			component: ChannelsList,
			props: true,
			meta: {
				permissions: routesPermissions.channels,
				pageTitle: 'Список каналов - ' + defaultPageTitle,
			}
		},
		
		// Dictionariy edit
		{
			name: 'dictionary',
			path: ENavy.dictionary,
			component: DictionaryEdit,
			props: true,
			meta: {
				permissions: routesPermissions.dictionary,
				pageTitle: 'Редактирование словаря - ' + defaultPageTitle, 
			}
		},

			// Moderators edit
			{
				name: 'moderators',
				path: ENavy.moderators,
				component: ModeratorsEdit,
				props: true,
				meta: {
					permissions: routesPermissions.moderators,
					pageTitle: 'Редактирование модераторов - ' + defaultPageTitle, 
				}
			},

		// FAQ LIST
		{
			name: 'faqQuestions',
			path: ENavy.faqQuestions,
			component: FaqQuestionsList,
			props: true,
			meta: {
				permissions: routesPermissions.faqQuestions,
				pageTitle: 'Список вопросов FAQ - ' + defaultPageTitle,
			}
		},

		// Edit page FAQ
		{
			name: 'faq-questions-edit',
			path: ENavy.faqQuestionEdit,
			component: FaqQuestion,
			props: true,
			meta: {
				permissions: routesPermissions.faqQuestionEdit,
				pageTitle: 'Редактирование FAQ - ' + defaultPageTitle,
			}
		},

		// Create page FAQ
		{
			name: 'faq-create',
			path: ENavy.faqQuestionCreate,
			component: FaqQuestion,
			props: true,
			meta: {
				permissions: routesPermissions.faqQuestionCreate,
				pageTitle: 'Создание FAQ - ' + defaultPageTitle,
			}
		},

		// FAQ category
		{
			name: 'faqCategory',
			path: ENavy.faqCategory,
			component: FaqCategory,
			props: true,
			meta: {
				permissions: routesPermissions.faqCategory,
				pageTitle: 'FAQ категории - ' + defaultPageTitle,
			}
		},

		// CMI
		{
			name: 'cmi',
			path: ENavy.cmi,
			component: Cmi,
			props: true,
			meta: {
				permissions: routesPermissions.cmi,
				pageTitle: 'OMC - ' + defaultPageTitle, 
			}
		},

		// CMI
		{
			name: 'cmi-editor',
			path: ENavy.cmiEditor,
			component: CmiDealViewerEditor,
			props: true,
			meta: {
				permissions: routesPermissions.cmiEditor,
				pageTitle: 'OMC - ' + defaultPageTitle, 
			}
		},
		// CMI
		{
			name: routeNames.cmiPost,
			path: ENavy.cmiPost,
			component: PostPage,
			// props: true,
			meta: {
				permissions: routesPermissions.cmiPost,
				pageTitle: 'OMC. Почтовые отправки - ' + defaultPageTitle, 
			}
		},
		// CMI
		{
			name: routeNames.cmiPostEditor,
			path: ENavy.cmiPostEditor,
			component: PostViewerEditorPage,
			// props: true,
			meta: {
				permissions: routesPermissions.cmiPostEditor,
				pageTitle: 'OMC. Почтовые отправки - ' + defaultPageTitle, 
			}
		},

		// // Отзывы
		// {
		//   name: routeNames.reviews,
		//   path: ENavy.reviews,
		//   component: Dashboard,
		//   meta: {
		//     permissions: routesPermissions.reviews 
		//   }
		// },

		// Администрирование
		{
			name: routeNames.admin,
			path: ENavy.admin,
			component: Dashboard,
			meta: {
				permissions: routesPermissions.admin,
				pageTitle: 'Администрирование - ' + defaultPageTitle, 
			}
		},
		{
			name: routeNames.userRulesAdministration,
			path: ENavy.userRulesAdministration,
			component: PermissionsPage,
			meta: {
				permissions: routesPermissions.userRulesAdministration,
				pageTitle: 'Управление правами - ' + defaultPageTitle, 
			}
		},
		{
			name: routeNames.userAdministration,
			path: ENavy.userAdministration,
			component: UserListPage,
			meta: {
				permissions: routesPermissions.userAdministration,
				pageTitle: 'Управление пользователями - ' + defaultPageTitle, 
			}
		},

		{
			name: routeNames.userPage,
			path: ENavy.userPage,
			component: UserPage,
			meta: {
				permissions: routesPermissions.userPage,
				pageTitle: 'Просмотр пользователя - ' + defaultPageTitle, 
			}
		},

		{
			name: routeNames.userEditPage,
			path: ENavy.userEditPage,
			component: UserPage,
			meta: {
				permissions: routesPermissions.userEditPage,
				pageTitle: 'Управление пользователем - ' + defaultPageTitle, 
			}
		},

		// kit, только для дев среды
		...( devMode ? [{
			name: routeNames.kit,
			path: ENavy.kit,
			component: DevKit,
		}]: []),

		// // login
		// {
		// 	// name: routeNames.login,
		// 	path: ENavy.login,
		// 	component: LoginPage
		// },

		// 404 - всегда последним элементом, vue пойдёт по роутам каскадом
		{
			path: '/:catchAll(.*)',
			component: NotFound
		}
	]
});


// закрываем мобильную навигацию
router.beforeResolve(()=>{
	const navyStore = useNavyStore();
	navyStore.closeMobileNavy();
});

// guard для проверки прав юзера на страницу
router.beforeResolve( async (to, from, next) => {
  
	// получаем права страницы
	const pathPermissions = to?.meta?.permissions || [];

	const userStore = useUserStore();
	// проверяем статус логина
	const isAuth = await isUserAuth();
	if (!isAuth) {
		if (to.path !== ENavy.login) {
			next(ENavy.login);
		}
		else {
			next();
		}
		return;
	}

	// если залогинен и страница логина - идём на dashboard
	if (isAuth && to.path === ENavy.login) {
		next('/');
		return;
	}

	// получаем права юзера
	userStore.matchAuth();

	if (Array.isArray(pathPermissions) && pathPermissions.length) {
		// если данные о юзере ещё грузятся, то ожидаем их
		const awaitUserPermissions = async (cb: (list: number[])=>void) => {
			if (!userStore.loading) {
				return cb(userStore.permissions);
			}
			setTimeout(()=>awaitUserPermissions(cb), 100);
		};

		const userPermissions: number[] = await new Promise(res=>{
			awaitUserPermissions(res);
		});

		// проверяем, есть ли хотя бы одно право на страницу
		const isSuccess = pathPermissions.length === 0 || [...userPermissions].filter(perm => {
			return ~pathPermissions.indexOf(perm)
		}).length;

		if (!isSuccess) {
			next(ENavy.login);
			return;
		}
	}

	next();
});

// route before resolve hook
// router.beforeResolve(async (to, from, next)=>{})

// pagetitle
router.afterEach((to) => {
	// устанавливаем title для страницы
	const pageTitle = to?.meta?.pageTitle || defaultPageTitle;
	(window as any).document.title = pageTitle;
});