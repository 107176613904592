import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-lg-6" }
const _hoisted_2 = {
  key: 0,
  class: "channel-form mb-4"
}
const _hoisted_3 = {
  key: 0,
  class: "error-message"
}
const _hoisted_4 = {
  key: 1,
  class: "error-message"
}
const _hoisted_5 = ["disabled"]

import { ref, defineComponent, onMounted } from 'vue';
import {
  Card,
  CardTitle,
  Row,
  MinWidthAndScroll,
  Table,
  TablePagination,
} from '@/components/kit';
import AllChannelsFilters from './components/AllChannelsFilters.vue';
import { 
  EmptyTrChannels,
  RowModerators as TableRow
 } from './components/';
import { useAsyncChannelAllListStore } from './store/';
import { isUserCanEditChannel, isUserCanViewChannels } from '@/store';
import { createChannel, getChannelsList } from './bff-service'; // Добавьте getChannelsList
import ChannelDelete from './components/ChannelDelete.vue';

// Store

export default /*@__PURE__*/_defineComponent({
  __name: 'ChannelsList',
  setup(__props) {

const tableStore = useAsyncChannelAllListStore();

tableStore.columns = [
  {
    key: 'id',
    title: '#',
  },
  {
    key: 'channel_id',
    title: 'ID чата',
    searchable: true,
    filterable: true,
  },
  {
    key: 'name',
    title: 'Имя чата',
    searchable: true,
    filterable: true,
  },
  { key: 'edit',
    title: 'Удаление чата',
    component: ChannelDelete
  },
];
tableStore.paginateInfoTpl = '_DATA_ из _TOTAL_';
tableStore.body = { emptyComponent: EmptyTrChannels };
tableStore.head = { class: 'head-no-select' };
if (!tableStore.row) {
  tableStore.row = {};
}
tableStore.row.component = TableRow;

onMounted(() => {
  tableStore.clearFilters();
  tableStore.getData();
});

if (isUserCanViewChannels()) {
  tableStore.getData();
}

const showChannelForm = ref(false);
const newChannel = ref({
  channel_id: '',
  name: '',
});

const errorMessages = ref({
  channel_id: '',
  name: '',
});

const toggleChannelForm = () => {
  showChannelForm.value = !showChannelForm.value;
};

const validateChannelId = (event: Event) => {
  const input = event.target as HTMLInputElement;
  input.value = input.value.replace(/[^0-9-]/g, ''); 
  newChannel.value.channel_id = input.value;
};

const validateChannelName = (event: Event) => {
  const input = event.target as HTMLInputElement;
  input.value = input.value.replace(/[^a-zA-Zа-яА-Я0-9_@\s]/g, ''); 
  newChannel.value.name = input.value;
};

const validateChannel = async () => {
  const errors = {
    channel_id: '',
    name: '',
  };

  const response = await getChannelsList(1, 10, { channel_id: newChannel.value.channel_id });
  if (response && response.list.some(item => item.channel_id === newChannel.value.channel_id)) {
    errors.channel_id = 'Чат с таким ID уже существует';
  }

  const responseByName = await getChannelsList(1, 10, { name: newChannel.value.name });
  if (responseByName && responseByName.list.some(item => item.name === newChannel.value.name)) {
    errors.name = 'Чат с таким Именем уже существует';
  }

  errorMessages.value = errors;

  return !errors.channel_id && !errors.name;
};

const submitChannel = async () => {
  newChannel.value.name = newChannel.value.name.trim();

  const isValid = await validateChannel();
  if (!isValid) return;

  const response = await createChannel(newChannel.value);
  if (response) {
    newChannel.value = {
      channel_id: '',
      name: ''
    };
    showChannelForm.value = false;
    tableStore.getData();
  }
};

defineComponent({ name: 'AllChannelsTable' });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(isUserCanViewChannels)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 0,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Нет прав на просмотр данных")
              ])),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(isUserCanEditChannel)())
      ? (_openBlock(), _createBlock(_unref(Card), {
          key: 1,
          style: {"position":"relative"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CardTitle), null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Список чатов")
              ])),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("p", null, [
                _createElementVNode("button", {
                  class: "btn btn-inverse-primary btn-fw",
                  onClick: toggleChannelForm
                }, " Добавление чата ")
              ]),
              _createVNode(_unref(Row), null, {
                default: _withCtx(() => [
                  (showChannelForm.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _cache[4] || (_cache[4] = _createElementVNode("label", { class: "key-label margin-top channel-label" }, "Введите ID чата", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "margin-top form-control",
                          onInput: validateChannelId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((newChannel.value.channel_id) = $event)),
                          placeholder: "ID (Цифры и знак минуса)"
                        }, null, 544), [
                          [_vModelText, newChannel.value.channel_id]
                        ]),
                        (errorMessages.value.channel_id)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(errorMessages.value.channel_id), 1))
                          : _createCommentVNode("", true),
                        _cache[5] || (_cache[5] = _createElementVNode("label", { class: "key-label margin-top channel-label" }, "Введите наименование чата", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "margin-top form-control",
                          onInput: validateChannelName,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newChannel.value.name) = $event)),
                          placeholder: "Имя чата ( Кирилица, Латиница, цифры и знаки @ _ )"
                        }, null, 544), [
                          [
                            _vModelText,
                            newChannel.value.name,
                            void 0,
                            { trim: true }
                          ]
                        ]),
                        (errorMessages.value.name)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(errorMessages.value.name), 1))
                          : _createCommentVNode("", true),
                        _createElementVNode("button", {
                          class: "btn btn-success margin-both",
                          onClick: submitChannel,
                          disabled: !newChannel.value.channel_id.trim() || !newChannel.value.name.trim()
                        }, " Создать ", 8, _hoisted_5)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _createVNode(_unref(Row), null, {
              default: _withCtx(() => [
                _createVNode(_unref(MinWidthAndScroll), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Table), { "use-store": _unref(useAsyncChannelAllListStore) }, {
                      default: _withCtx(() => [
                        _createVNode(AllChannelsFilters)
                      ]),
                      _: 1
                    }, 8, ["use-store"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(TablePagination), {
              class: "tab-content tab-transparent-content",
              "use-store": _unref(useAsyncChannelAllListStore)
            }, null, 8, ["use-store"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})