<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { ITableCellProps } from '@/components/Table/types';

const props = defineProps<ITableCellProps>();
const question = props.value;
const alternative_question = props.rowData.faq_question_variants.map((item: { variant_text: string}) => item.variant_text);
</script>

<template>
  <td class="table__td-faq-question">
    <p><i class="mdi mdi-file-check"></i> {{ question }}</p>
    <p v-for="(alt, index) in alternative_question" :key="index">
      <i class="mdi mdi-file-delimited"></i> {{ alt }}
    </p>
  </td>
</template>

<style >
.table__td-faq-question .mdi-file-check,
.table__td-faq-question .mdi-file-delimited {
  color: rgb(142, 148, 169);
}

.table__td-faq-question p{
  max-width: 600px;
  white-space: pre-wrap;
  overflow: hidden;
  margin: 0;
}
</style>